<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <FormRow>
          <template v-slot:label>QR検索・選択</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-select">
                <SearchSelect
                  ref="searchSelect"
                  singleLabel="name"
                  :options="qrTicketList"
                  :multiple="false"
                  :closeOnSelect="true"
                  :searchKeyMinLength="searchKeyLength"
                  :searchAction="searchAction"
                  :resetOptions="resetOptions"
                  :searchField="searchField"
                  :params="pointParams"
                  @change-selection="handleSearchQrTicket"
                />
              </div>
            </div>
          </template>
        </FormRow>
      </section>
      <section class="section">
        <p class="form-label">ワンタイムトークン発行履歴</p>
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    ref="table"
                    :storeModule="storeModule"
                    :dataTable="dataTable"
                    :labels="labels"
                    @handle-toggle="handleStatus"
                    @select-items="handleSelectedItems"
                    :count="releaseOtpCount"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :listLength="releaseOtpCount"
                  :searchConditions="searchConditions"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid" v-if="!isClientChild">
          <li class="listGrid-item">
            <button :disabled="!isSelectedReleaseOtpExisted" class="btn btn-red" @click="showDeleteModal">削除</button>
          </li>
          <li class="listGrid-item pos-end">
            <button @click="handleShowOneTimeTokenModal" class="btn btn-main">トークン発行</button>
          </li>
        </ul>
      </template>
    </PageFooter>
    <ModalConfirm
      :handleCloseModal="() => closeModal('deletedModal')"
      :isConfirmModalShown="deletedModal"
      :handleConfirmModal="deleteReleaseOtp"
      title=" 対象のワンタイムトークンを削除しますか？"
    />
    <RegisterOtpDialog :isOpen.sync="statusModal" @get-release-otp="getReleaseOtp" @reset-pagination="resetPagination"/>
    <OnetimeTokenDialog @on-reset-checkbox="onResetCheckbox" :isDisable="isDisable" @handle-click-download="hanldeClickDownload" :isOpen.sync="oneTimeTokenModal" :otpName="otpName" :releaseOtpId="releaseOtpId"/>
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//constants
import { StatusConstants } from '@/constants/status';
// components
import SearchSelect from '@/components/SearchSelect.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import FormRow from '@/components/FormRow.vue';
import OnetimeTokenDialog from './_OnetimeTokenDialog.vue'
import RegisterOtpDialog from './_RegisterOtpDialog.vue'
import ModalConfirm from '@/components/ModalConfirm.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';
//helpers
import { formatDate, endline } from '@/helpers/formatData';
//mixins
import nav from '@/mixins/nav/point';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import error from '@/mixins/plugin/error';
import download from '@/mixins/plugin/download';

export default {
  data: function() {
    return {
      pageName: 'ポイント',
      selectedReleaseOtp: {},
      searchConditions: {},
      searchFields: ['qrPointId'],
      searchField: 'name',
      deletedModal: false,
      statusModal: false,
      isPublic: 0,
      qrId: null,
      oneTimeTokenModal: false,
      releaseOtpId: '',
      otpName: '',
      selectedItems: {
        allFlag: false,
        id: [],
      },
      storedField: 'idRequestExportOtp',
    };
  },
  components: {
    TableWithCheckbox,
    PaginationLog,
    FormRow,
    OnetimeTokenDialog,
    ModalConfirm,
    ModalConfirmDownload,
    RegisterOtpDialog,
    SearchSelect,
  },
  mixins: [modal, nav, search, error, download],
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
    }),
    releaseOtpList() {
      return this.isPointCommon ? this.parentReleaseOtpList : this.childReleaseOtpList;
    },
    releaseOtpCount() {
      return this.isPointCommon ? this.parentReleaseOtpCount : this.childReleaseOtpCount;
    },
    modulePath() {
      const action = this.isPointCommon ? 'getParentReleaseOtpList' : 'getChildReleaseOtpList';
      return `${this.storeModule}/${action}`;
    },
    isSelectedReleaseOtpExisted() {
      return this.selectedReleaseOtp?.ids?.length > 0 || this.selectedReleaseOtp.allFlag;
    },
    labels() {
      return [
        ...(!this.isClientChild ? [{ isCheckbox: true }] : []),
        { key: 'qrPoint', name: 'QRコード名称', tdClass: 'pre-line' },
        { key: 'createDate', name: '発行日' },
        { key: 'number', name: '発行数' },
        { key: 'validDays', name: '有効期限' },
        { key: 'status', name: 'ステータス' },
        { isButtonColumn: true }
      ];
    },
    dataTable() {
      return (
        (this.releaseOtpList || []).map((item) => {
          const isBtnDownloadDisabled = this.isDisable ? 'btn-disabled' : '';
          return {
            isRowDisabled: item.status === StatusConstants.otpReleaseStatus[0].value,
            ...item,
            qrPoint: {
              content: endline(item.qrPoint?.title, 10),
              routeName: this.isPointCommon ? 'EventParentPointQREdit' : 'EventChildPointQREdit',
              params: { id: item.qrPoint?.id },
              isLink: true,
            },
            createDate: formatDate(item.createDate),
            validDays: item.validDays + '日',
            status: StatusConstants.otpReleaseStatus.find(status => status.value === item.status)?.label,
            buttonLinks: [
              {
                content: '一覧',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.openQtpModal(item),
              },
              {
                content: 'CSV',
                class: `btn btn-sm btn-bd-main ${isBtnDownloadDisabled}`,
                function: () => this.hanldeClickDownload(item.id),
              },
            ],
          };
        })
      );
    },
    pointParams() {
      return {
        checkAvailable: 0
      }
    }
  },
  methods: {
    handleStatus(id, newStatus) {
      this.showModal('statusModal');
      this.qrId = id;
      this.releaseOtpList.find((el) => el.id === id).validFlag = newStatus;
      this.isPublic = newStatus ? 1 : 0;
    },
    async openQtpModal(releaseOtp) {
      this.releaseOtpId = releaseOtp.id;
      this.otpName = releaseOtp.qrPoint?.title;
      this.oneTimeTokenModal = true;
    },
    async getReleaseOtp() {
      await this.$store.dispatch(this.modulePath);
    },
    handleSelectedItems(items) {
      this.selectedReleaseOtp = items;
    },
    showDeleteModal() {
      if (this.isSelectedReleaseOtpExisted) {
        this.showModal('deletedModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    async deleteReleaseOtp() {
      const action = this.isPointCommon ? 'deleteParentReleaseOtp' : 'deleteChildReleaseOtp';
      const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
        ids: this.selectedReleaseOtp.ids,
        allFlag: this.selectedReleaseOtp.allFlag,
        searchCondition: {
          ...this.searchConditions,
        },
      });
      this.$refs.table.resetCheck();
      this.closeModal('deletedModal');
      if (result) {
        if (result.count) {
          this.$message.deleted('oneTimeToken');
        } else {
          this.$message.showError('someoneUpdated');
        }
        this.$refs.pagination.resetPagination();
      }
    },
    handleSearchQrTicket(rqTicket) {
      this.searchForm.qrPointId = rqTicket?.id;
      this.search();
      if (this.releaseOtpList?.length) {
        this.$refs.table.resetCheck();
      }
    },
    handleShowOneTimeTokenModal() {
      this.showModal('statusModal');
    },
    resetPagination() {
      this.$refs.pagination.resetPagination();
      this.resetSearch();
    },
    onResetCheckbox(data) {
      if (data) {
        this.$refs.table.resetCheck();
        this.$refs.pagination.resetPagination();
      }
    },
    async hanldeClickDownload(id) {
      this.isDisable = true;
      this.releaseOtpId = id;
      const action = this.isPointCommon ? 'downloadParentReleaseOtpList' : 'downloadChildReleaseOtpList';
      const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
        releaseOTPId: id,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.qr-code {
  flex-wrap: nowrap;
  align-items: flex-start;
}

.qr-title {
  width: 86px;
  margin-right: 10px;
}

.sub-title {
  width: 72px !important;
}
</style>
