<template>
  <div>
    <Modal v-if="isOpen">
      <template v-slot:headline>
        ワンタイムトークン発行
      </template>
      <template v-slot:body>
        <FormRow style="margin-bottom: 15px;" :required="true">
          <template v-slot:label>QRコード名称選択</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-select">
                <SearchSelect
                  ref="searchSelect"
                  singleLabel="name"
                  :isError="errors.qrPointId"
                  :options="qrTicketList"
                  :multiple="false"
                  :closeOnSelect="true"
                  :searchKeyMinLength="searchKeyLength"
                  :searchAction="searchAction"
                  :resetOptions="resetOptions"
                  :searchField="searchField"
                  @change-selection="handleSearchQrTicket"
                />
              </div>
            </div>
          </template>
        </FormRow>
        <FormRow style="margin-bottom: 15px;" :required="true">
          <template v-slot:label>件数入力</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-group">
                <div class="form-group-item">
                  <input
                    :class="{ 'form-control': true, 'is-error': errors.number }"
                    type="tel"
                    v-number
                    v-trim
                    v-maxlength
                    maxlength="3"
                    name="number"
                    v-model="form.number"
                  />
                </div>
                <span>件分</span><span class="ml-10">ワンタイムトークンを発行します。</span>
              </div>
            </div>
          </template>
        </FormRow>
        <FormRow :required="true">
          <template v-slot:label>有効期限</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-group">
                <div class="form-group-item">
                  <input
                    :class="{ 'form-control': true, 'is-error': errors.validDays }"
                    type="tel"
                    v-number
                    v-trim
                    v-maxlength
                    maxlength="3"
                    name="validDays"
                    v-model="form.validDays"
                  />
                </div>
                <span>日</span>
              </div>
            </div>
          </template>
        </FormRow>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button
              class="btn btn-white"
              type="button"
              @click="cancelRegisterModal()"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="handleRegistOneTimeToken" button-text="発行する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { isBetweenWithNoLabel } from '@/helpers/validators';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
//component
import Modal from '@/components/Modal.vue';
import FormRow from '@/components/FormRow.vue';
import SearchSelect from '@/components/SearchSelect.vue';
//mixins
import error from '@/mixins/plugin/error';
import modal from '@/mixins/plugin/modal';
import nav from '@/mixins/nav/point';

export default {
  name: 'ModalRegisterOneTimeTokenQr',
  props: ['isOpen'],
  data: function() {
    return {
      form: {
        qrPointId: '',
        number: '',
        validDays: ''
      },
      searchField: 'name',
    };
  },
  components: {
    FormRow,
    Modal,
    SearchSelect,
  },
  validations() {
    let form = {
      qrPointId: { required },
      number: { required, oneTimeTokenBetween :isBetweenWithNoLabel(1, 500) },
      validDays: { required, validDaysBetween: isBetweenWithNoLabel(0, 365) },
    }
    return { form };
  },
  mixins: [modal, error, nav],
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    async handleRegistOneTimeToken() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'oneTimeToken');
      } else {
        this.errors = {};
        this.$message.reset();
        const action = this.isPointCommon ? 'createParentReleaseOtp' : 'createChildReleaseOtp';
        const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
          subdomain: this.subdomain,
          data: this.form
        });
        if (result) {
          this.cancelRegisterModal();
          this.$emit('get-release-otp');
          this.$message.created('oneTimeToken');
          this.$emit('reset-pagination');
        }
      }
    },
    cancelRegisterModal() {
      this.form = {
        qrPointId: '',
        number: '',
        validDays: ''
      };
      this.$emit('update:isOpen', false);
      this.$message.reset();
      this.resetErrors(this.errors);
    },
    handleSearchQrTicket(rqTicket) {
      this.form.qrPointId = rqTicket?.id;
    },
  },
}
</script>

<style>

</style>
